<template>
  <b-container class="dashboad bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <h1>Simple view with bootstrap table</h1>

        <p class="lead">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>

        <h2>Bootstrap size</h2>
        <div class="d-block d-sm-none">SM</div>
        <div class="d-none d-sm-block d-md-none">SM</div>
        <div class="d-none d-md-block d-lg-none">MD</div>
        <div class="d-none d-lg-block d-xl-none">LG</div>
        <div class="d-none d-xl-block">XL</div>

        <div class="table-container">
          <b-table class="spirecta-simple-table" show-empty hover responsive striped
                   :items="items"
                   :fields="fields"
                   :filter="filter"
                   @filtered="onFiltered"
                   stacked="md"
            >
            <!-- Filter -->
            <template slot="top-row">
              <td colspan="5">
                <div class="d-flex align-items-center">
                  <i class="fa fa-search"></i>
                  <b-form-input v-model="filter" size="sm" :placeholder="$t('cashbook.overview.transaction_table.filter_placeholder')" />
                </div>
              </td>
            </template>
            <!-- EDIT / DELETE BUTTONS -->
            <template slot="actions" slot-scope="row">
              <button class='btn plain-btn text-regular mr-3 action-button' @click="openCategoryModal(row.item)">
                <i class="flaticon solid edit-3 text-primary"></i> {{ $t('common.edit')}}
              </button>
              <button class='btn plain-btn text-regular mr-3 action-button' @click="deleteCategory(row.item.id)">
                <i class="flaticon stroke x text-danger"></i> {{ $t('common.delete')}}
              </button>
            </template>
            <template slot="HEAD_actions">
              <button
                class="title-button btn btn-sm btn-primary font-weight-bold add-new-row d-none d-sm-block float-right"
                @click="openCategoryModal()">
                <i class="flaticon stroke plus"></i> {{ $t('common.add_new')}}
              </button>
            </template>
          </b-table>
          <pagination :totalRows="totalRows" :currentPage.sync="currentPage" :perPage.sync="perPage"></pagination>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import pagination from '@/components/common/pagination'

export default {
  name: 'SimpleView',
  data () {
    return {
      // B-table
      // Note 'age' is left out and will not appear in the rendered table
      fields: {
        first_name: {
          label: 'Person first name',
          sortable: true
        },
        last_name: {
          label: 'Person last name',
          sortable: true
        },
        city: {
          key: 'address.city',
          label: 'City',
          sortable: true
        },
        'address.country': {
          label: 'Country',
          sortable: true
        },
        actions: {
          key: 'actions',
          class: 'd-lg-flex justify-content-lg-end align-items-center'
        }
      },
      items: [
        {
          id: 1,
          age: 40,
          first_name: 'Susan',
          last_name: 'Macdonald',
          address: { country: 'USA', city: 'New York' }
        },
        {
          id: 2,
          age: 21,
          first_name: 'Sophie',
          last_name: 'Shaw',
          address: { country: 'Canada', city: 'Toronto' }
        },
        {
          id: 3,
          age: 89,
          first_name: 'Martin',
          last_name: 'Wilson',
          address: { country: 'Australia', city: 'Sydney' }
        },
        {
          id: 4,
          age: 38,
          first_name: 'Jami',
          last_name: 'Carney',
          address: { country: 'England', city: 'London' }
        }
      ],
      filter: null,

      // Pagination
      currentPage: 1,
      totalRows: 4,
      perPage: 5 // Should match select in pagination component
    }
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    }
  },
  components: {
    pagination
  }
}
</script>

<style lang="scss">

</style>
